import {toBlobURL} from "@ffmpeg/util";
import {FFmpeg} from "@ffmpeg/ffmpeg";

export type VideoMetaData = {
    duration: number,
    hasSubtitles: boolean,
    width: number,
    height: number,
}
const DURATION_PATTERN = /Duration:\s*([0-9]{2}):([0-9]{2}):([0-9]{2}.[0-9]{0,2})/m;
const DIMENSIONS_PATTERN = /Stream #0:[^:]+: Video: .+, (\d+)x(\d+)/;

export default class extends FFmpeg {

    private readonly _load: (args: any) => Promise<boolean>;

    public constructor() {
        super();
        this._load = this.load;
        this.load = () => {
            return this.getConfig().then(this._load);
        }

    }

    private async getConfig() {
        const coreURL = await toBlobURL(`/ffmpeg/ffmpeg-core.js`, 'text/javascript');
        const wasmURL = await toBlobURL(`/ffmpeg/ffmpeg-core.wasm`, 'application/wasm');
        const classWorkerURL = await toBlobURL(`/ffmpeg/ffmpeg-worker.js`, 'text/javascript');
        return { coreURL, wasmURL, classWorkerURL };
    }

    protected getMetadata(inputFile: string): Promise<string> {
        return new Promise((resolve, rej) => {
            let log = '';
            let metadataLogger = (e: any) => {
                log += e.message;
                if (e.message.indexOf('Aborted()') > -1) {
                    this.off('log', metadataLogger);
                    resolve(log);
                }
            };
            this.on('log', metadataLogger);
            this.exec(["-i", inputFile]).catch(rej);
        });
    }

    public async getVideoMetaData(inputFile: string): Promise<VideoMetaData> {
        const metadata = await this.getMetadata(inputFile);
        const hasSubtitles = metadata.match(/Stream #0:(\d+)(\(\w+\))?: Subtitle: /) !== null;
        const dimensionsMatch = DIMENSIONS_PATTERN.exec(metadata);
        const width = dimensionsMatch ? parseInt(dimensionsMatch[1]) : 0;
        const height = dimensionsMatch ? parseInt(dimensionsMatch[2]) : 0;
        const durationMatch = DURATION_PATTERN.exec(metadata);
        const duration = durationMatch ? (parseInt(durationMatch[1]) * 3600) + (parseInt(durationMatch[2]) * 60) + (parseInt(durationMatch[3])) : 0;
        return { duration, hasSubtitles, width, height };
    }
}
