import React, {useEffect, useMemo, useRef} from 'react';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import {Textarea, Tooltip} from "flowbite-react";
// @ts-ignore
import autosize from 'autosize';
import {faCircleMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Subtitle} from "@/types";
import {timestampToSeconds} from "@/utils/subtitleUtils";

interface VirtualizedListProps {
    items: Subtitle[];
    currentItemIdx: number | null;
    onSetCurrentItemIdx: (index: number) => void;
    updateItem: (index: number, value: Subtitle) => void;
    deleteItem: (index: number) => void;
    className?: string;
}

type TimeField = 'startTime' | 'endTime';

const SubtitleList: React.FC<VirtualizedListProps> = ({ items, currentItemIdx, updateItem, deleteItem, className, onSetCurrentItemIdx }) => {
    const [filteredItems, setFilteredItems] = React.useState<Subtitle[]>(items);
    const listRef = useRef<List|null>(null);
    const cursorPositionRef = useRef<number | null>(null);

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        cursorPositionRef.current = e.target.selectionStart;
        const updatedItem = { ...filteredItems[index], text: e.target.value };
        updateItem(getOriginalIndex(index), updatedItem);
    };

    const validateTimestamp = (value: string, field: TimeField, idx: number): boolean => {
        const regex = /^([0-9]{2}):([0-9]{2}):([0-9]{2}),([0-9]{3})$/;
        const match = value.match(regex);
        if (!match) {
            return false;
        }
        const valueSeconds = timestampToSeconds(value);
        const counterPartField = field === 'startTime' ? 'endTime' : 'startTime';
        const counterPartValue = timestampToSeconds(filteredItems[idx][counterPartField])
        if (field === 'startTime' && valueSeconds >= counterPartValue) {
            return false;
        }
        if (field === 'endTime' && valueSeconds <= counterPartValue) {
            return false;
        }
        if (field === 'startTime') {
            const previous = idx > 0 ? filteredItems[idx - 1] : null;
            if(previous) {
                const previousSeconds = timestampToSeconds(previous.endTime);
                if (valueSeconds < previousSeconds) {
                    return false;
                }
            }
        } else if (field === 'endTime') {
            const next = idx < filteredItems.length - 1 ? filteredItems[idx + 1] : null;
            if (next) {
                const nextSeconds = timestampToSeconds(next.startTime);
                if (valueSeconds > nextSeconds) {
                    return false;
                }
            }
        }
        return true;
    }

    const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, field: TimeField) => {
        e.target.classList.remove('bg-red-100');
        if(!validateTimestamp(e.target.value, field, index)) {
            e.target.classList.add('bg-red-100');
        }
    }

    const publishTimeChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, field: TimeField) => {
        const value = e.target.value;
        if(validateTimestamp(value, field, index) && value !== filteredItems[index][field]) {
            const updatedItem = { ...filteredItems[index], [field]: value };
            updateItem(getOriginalIndex(index), updatedItem);
            listRef.current?.forceUpdateGrid();
        } else {
            e.target.value = filteredItems[index][field];
        }
        e.target.classList.remove('bg-red-100');
    }

    useEffect(() => {
        setFilteredItems(items.filter(el => !el.deleted))
    }, [items]);

    const getOriginalIndex = (index: number) => {
        return items.findIndex(el => el.id === filteredItems[index].id);
    }

    const rowRenderer = ({ key, index, style, parent }: {
        key: string;
        index: number;
        style: React.CSSProperties;
        parent: any;
    }) => {
        const item = filteredItems[index];
        const numLines = item.text.split('\n').length;
        return (
                <div onClick={() => onSetCurrentItemIdx(index)} key={item.id} style={style} className={`subtitle-item px-4 ${index + 1 < items.length ? 'border-b border-gray-200 border-solid ' : ''}  relative group flex items-center justify-between`}>
                    <div className="border-r pr-5 h-full w-24 flex flex-col justify-center relative ">
                        <div className="absolute top-[40%] right-0 w-2 opacity-0 group-hover:opacity-100 transition">
                            <Tooltip theme={{target: `inline-flex`}} content="Remove">
                            <FontAwesomeIcon
                                onClick={() => deleteItem(getOriginalIndex(index))}
                                className={`align-middle w-4 h-4 items-center text-red-200 hover:text-red-600 cursor-pointer `}
                                icon={faCircleMinus}
                            />
                            </Tooltip>
                        </div>
                       <span className="text-gray-400"> {item.id}</span>
                        <div>
                            <input
                                key={item.startTime}
                                onBlur={(e) => publishTimeChange(e, index, 'startTime')}
                                onChange={(e) => handleTimeChange(e, index, 'startTime')}
                                type="text" defaultValue={item.startTime} className="text-xs hover:bg-amber-50 cursor-pointer p-0 border-0 w-[11ch] block mb-1 rounded-sm" />
                        </div>
                        <div>
                            <input type="text" defaultValue={item.endTime}
                                      key={item.endTime}
                                      onBlur={(e) => publishTimeChange(e, index, 'endTime')}
                                      onChange={(e) => handleTimeChange(e, index, 'endTime')}
                                   className="text-xs hover:bg-amber-50 cursor-pointer  p-0 border-0    w-[11ch] block rounded-sm"/>
                        </div>
                    </div>
                    <div className="grow ml-6">
                        <Textarea
                            dir="auto"
                            className={`${numLines >= 4 ? 'text-xs' : 'text-base'} hover:bg-amber-50 p-0 border-0 bg-white focus:outline-none focus:border-0 ${currentItemIdx === index ? 'text-blue-700' : ''}`}
                            value={item.text}
                            style={{ maxHeight: '4.5rem' }}
                            onChange={(e) => handleTextareaChange(e, index)}
                            ref={(textarea) => {
                                if (textarea) {
                                    autosize(textarea);
                                    if (cursorPositionRef.current !== null) {
                                        textarea.selectionStart = cursorPositionRef.current;
                                        textarea.selectionEnd = cursorPositionRef.current;
                                    }
                                }
                            }}
                        />

                    </div>
                </div>
        );
    };

    return (
        <div className={className}>
            <AutoSizer>
                {({height, width}: { height: number, width: number }) => (
                    <List
                        ref={listRef}
                        scrollToIndex={currentItemIdx !== null ? currentItemIdx : undefined}
                        width={width}
                        height={height}
                        rowHeight={81}
                        rowRenderer={rowRenderer}
                        rowCount={filteredItems.length}
                        overscanRowCount={30}
                    />
                )}
            </AutoSizer>
        </div>
    );
};

export default SubtitleList;
