import Modal from "@/Components/Modal";
import TextInput from "@/Components/TextInput";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import {useEffect, useMemo, useState} from "react";
import debounce from 'debounce';
import Checkbox from "@/Components/Checkbox";
import {Subtitle} from "@/types";

export type Change = {
    index: number;
    item: Subtitle;
}

export type SearchModalProps = {
    show: boolean;
    onClose: () => void;
    items: Subtitle[];
    onUpdated: (changes: Change[]) => void;
}

export default function ({ show, onClose, items, onUpdated }: SearchModalProps) {
    const [search, setSearch] = useState<string>('')
    const [replace, setReplace] = useState<string>('')
    const [caseSensitive, setCaseSensitive] = useState<boolean>(false)
    const [useRegex, setUseRegex] = useState<boolean>(false)
    const [matchedItems, setMatchedItems] = useState<Subtitle[]>(items)

    const getSearchRegex = (search: string) => {
        const str = useRegex ? search : escapeRegExp(search)
        return new RegExp(`(${str})`, caseSensitive ? 'g' : 'gi')
    }

    const performReplace = () => {
        const regex = getSearchRegex(search)
        const changes = items.
            filter((item, idx) => item.text.match(regex))
            .map((item, idx) => {
            return {
                index: items.findIndex(i => i.id === item.id),
                item: {...item, text: item.text.replace(regex, replace)}
            }
        })
        onUpdated(changes)
    }

    const handleSearch = useMemo(() => debounce((value: string) => {
        if(value === '') {
            return setMatchedItems([])
        }
        const filtered = items.filter(item =>
            !item.deleted && item.text.match(getSearchRegex(value))
        )
        setMatchedItems(filtered)
    }, 400), [items, caseSensitive, useRegex])

    useEffect(() => {
        handleSearch(search)
    }, [search, caseSensitive, useRegex])

    const highlightText = (text: string) => {
        if(search === '') return text
        const regex = getSearchRegex(search)
        return text.split(regex).map((part, i) =>
            regex.test(part) ? <strong className="text-blue-600" key={i}>{part}</strong> : part
        );
    };

    const replacedText = (text: string) => {
        if(search === '') return text
        const regex = getSearchRegex(search);
        if (replace === '') {
            return text.split(regex).map((part, i) =>
                regex.test(part) ? <del key={i}>{part}</del> : part
            );
        } else {
            return text.split(regex).map((part, i) =>
                regex.test(part) ? <span key={i} className="text-blue-600 font-bold">{replace}</span> : part
            );
        }
    };

    return (
        <Modal maxWidth='xl' show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                <div className="flex items-center p-7">

                    <div className="flex-grow flex">
                        <h3 className="text-xl font-bold">Find and Replace</h3>
                    </div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            data-modal-hide="default-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="flex flex-col  px-7 mb-5">
                    <InputLabel className="sm:font-bold sm:text-base" htmlFor="search" value="Find"/>
                    <TextInput
                       id="search"
                       className="w-full mb-1" value={search}
                       onChange={e => setSearch(e.target.value)}
                       isFocused={true}
                    />
                    <span className="flex mb-5 mt-1 items-center">
                        <Checkbox id="case" className="mr-2" checked={caseSensitive} onClick={e => setCaseSensitive(!caseSensitive)}/>
                        <InputLabel htmlFor="case" className="inline" value="Case Sensitive" />

                        <Checkbox id="regex" className="mr-2 ml-4" checked={useRegex} onClick={e => setUseRegex(!useRegex)}/>
                        <InputLabel htmlFor="regex" className="inline" value="Regex" />
                    </span>
                    <InputLabel htmlFor="replace" className="sm:font-bold sm:text-base" value="Replace With"/>
                    <TextInput
                        id="replace"
                        value={replace}
                        className="w-full mb-8"
                        onChange={e => setReplace(e.target.value)}
                    />

                    {
                        matchedItems.length > 0 && (
                            <div className="flex  px-2">
                                <div className="w-1/2 font-bold text-sm mr-2">
                                    Original
                                </div>
                                <div className="w-1/2 font-bold text-sm">
                                    Result
                                </div>
                            </div>)
                    }


                        <div className=" bg-gray-50 rounded-md h-56 flex flex-col overflow-y-auto">
                    {
                        matchedItems.length ? (
                            matchedItems.map((item, index) => (
                                            <div key={index} className={`border-b border-gray-100 p-2 w-full flex ${index % 2 ? 'bg-white' : ''}`}>
                                                <div className="w-1/2 pr-2">
                                                    <span className="text-gray-600 text-sm leading-3">{highlightText(item.text)}</span>
                                                </div>
                                                <div className="w-1/2">
                                                    <span className="text-gray-600 text-sm leading-3">{replacedText(item.text)}</span>
                                                </div>

                                            </div>
                                    )
                                )) : (
                                <span className="text-gray-400 text-lg self-center flex justify-center items-center w-full h-full">
                                    <span>No results found</span>
                                </span>
                            )
                        }
                    </div>
                    {matchedItems.length > 0 && (<span className="text-xs p-2">{matchedItems.length} matches</span>)}
                    <PrimaryButton
                        disabled={matchedItems.length === 0}
                        onClick={performReplace}
                        className="mt-5 w-28 self-end mr-0">Replace All</PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}

function escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
