import Modal from "@/Components/Modal";
import {useEffect, useRef, useState} from "react";
import TextInput from "@/Components/TextInput";
import PrimaryButton from "@/Components/PrimaryButton";
import {faMagicWandSparkles, faGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CloseIcon from "@/Components/Icons/CloseIcon";
import {ucFirst} from "@/utils/textFormatting";
import {SubtitlePosition} from "@/types";
import VideoProbe from "@/services/video/VideoProbe";
import {MultiViewModal} from "@/Components/MultiViewModal";
import SubtitleRegionConfig from "@/Pages/Dashboard/Partials/SubtitleRegionSelect";

export type AdvancedSettingsModalProps = {
    show: boolean,
    onClose: () => void,
    onSave: (position: SubtitlePosition, yOffset?: [number, number]) => void,
    position?: SubtitlePosition,
    yOffsetRatio?: [number, number],
    videoFile?: File,
}

export const DEFAULT_SUBTITLE_POSITION: SubtitlePosition = 'auto';

export default function AdvancedSettingsModal({show, onClose, onSave, position: initPosition, yOffsetRatio, videoFile }: AdvancedSettingsModalProps) {
    const [position, setPosition] = useState<SubtitlePosition>(DEFAULT_SUBTITLE_POSITION);
    const [selectingCustomRegion, setSelectingCustomRegion] = useState(false);
    const [yOffset, setYOffset] = useState<undefined | [number, number]>()

    const save = () => {
        onSave(position, yOffset);
        onClose();
    }

    useEffect(() => {
        if(!show) {
            setTimeout(() => setSelectingCustomRegion(false), 350);
            setYOffset(undefined)
        }
    }, [show]);

    useEffect(() => {
        setPosition(initPosition ?? DEFAULT_SUBTITLE_POSITION);
    }, [initPosition, show]);

    const handleRegionSelection = (startY: number, endY: number) => {
        setYOffset([startY, endY]);
        setSelectingCustomRegion(false);
        setPosition('custom');
    }

    useEffect(() => {
        if(yOffsetRatio) {
            setYOffset(yOffsetRatio)
        }
    }, [yOffsetRatio]);

    return (<MultiViewModal
        currentIdx={selectingCustomRegion ? 1 : 0}
        onBack={() => setSelectingCustomRegion(false)}
        show={show}
        onClose={onClose}
        maxWidth={'md'}
        title="Advanced Settings"
    >
        <div className="px-8 pb-6 mt-5">
            <div className="mb-5">
                <label htmlFor="default-input"
                       className="block mb-1 text-sm font-black text-gray-900 dark:text-white">Subtitle
                    Region</label>
                <p className="text-sm text-gray-600">Choose the region of the video frame from which
                    subtitles will be extracted.</p>
            </div>
            <div className="flex">
                <SubtitlePositionButton position='auto' selected={position === 'auto'}
                                        onClick={() => setPosition('auto')}/>
                <SubtitlePositionButton position='bottom' selected={position === 'bottom'}
                                        onClick={() => setPosition('bottom')}/>
                <SubtitlePositionButton position='middle' selected={position === 'middle'}
                                        onClick={() => setPosition('middle')}/>
                <SubtitlePositionButton position='top' selected={position === 'top'}
                                        onClick={() => setPosition('top')}/>
                {
                    videoFile && (
                        <SubtitlePositionButton position='custom'
                            selected={position === 'custom'}
                            onClick={() => {
                                setSelectingCustomRegion(true)
                            }}
                        />
                    )
                }


            </div>

            <PrimaryButton className="w-full mt-12" onClick={save}>
                    <span className="flex justify-center items-center">
                        Save
                    </span>
            </PrimaryButton>
        </div>
        <div title="Select Subtitle Region">
            {videoFile && selectingCustomRegion &&
                <SubtitleRegionConfig
                    onSelection={handleRegionSelection}
                    offsetRatio={yOffset}
                    videoFile={videoFile}/>
            }
        </div>
    </MultiViewModal>)
}

type SubtitlePositionButtonProps = {
    position: SubtitlePosition,
    selected: boolean,
    onClick: () => void,
}

function SubtitlePositionButton({position, selected, onClick}: SubtitlePositionButtonProps) {
    let justifyContent = 'justify-center';
    let padding = '';

    if (position === 'top') {
        justifyContent = 'justify-start';
        padding = 'pt-2';
    } else if (position === 'bottom') {
        justifyContent = 'justify-end';
        padding = 'pb-2';
    }

    return (
        <div
            onClick={onClick}
            className={`cursor-pointer ${position === 'custom' ? 'hidden lg:flex' : ''}  bg-white text-gray-900 w-1/6 flex-col text-center mr-2`}>
            <div
                className={`transition-all bg-gray-100  ${selected ? 'border-2 border-blue-600' : 'border-2 border-white  hover:border-gray-300'}   rounded w-full h-20 lg:h-14  flex flex-col items-center ${justifyContent} ${padding}`}>
                {
                    position === 'auto' ?
                        <FontAwesomeIcon icon={faMagicWandSparkles} className={`text-gray-400 text-xl`}/> :
                        position === 'custom' ?
                            <FontAwesomeIcon icon={faGear} className={`text-gray-400 text-xl`}/> :
                            <>
                                <div className={`h-1 bg-gray-300 rounded-full w-[80%]`}></div>
                                <div className={`h-1 bg-gray-300 rounded-full w-[60%] mt-1`}></div>
                            </>
                }
            </div>
            <span className="text-sm  mt-2">{ucFirst(position)}</span>

        </div>
    )
}
