import {Link} from "@inertiajs/react";
import {PageProps} from "@/types";
import ApplicationLogo from "@/Components/ApplicationLogo";
import {useState} from "react";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink";

export default function Header({}) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);

    return (
        <header className="bg-gray-100 p-4 shadow ">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex ml-4  items-start md:items-center ">
                    <Link
                        href={route('home')}>
                        <ApplicationLogo/>
                    </Link>

                </div>
                <div className="sm:top-0 sm:right-0 px-6 text-end hidden sm:flex ">
                    <Link
                        href={route('login')}
                        className="font-semibold whitespace-nowrap text-gray-600 hover:text-gray-900 focus:outline focus:outline-2 focus:rounded-sm focus:outline-orange-500"
                    >
                        Log in
                    </Link>

                    <Link
                        href={route('register')}
                        className="ms-4 font-semibold text-gray-600 hover:text-gray-900  focus:outline focus:outline-2 focus:rounded-sm focus:outline-orange-500"
                    >
                        Sign up
                    </Link>
                    <Link
                        href={route('api.info')}
                        className={`ms-4 font-semibold text-gray-600 hover:text-gray-900  focus:outline focus:outline-2 focus:rounded-sm focus:outline-orange-500 ${route().current('api.info') ? '!text-black' : ''}`}
                    >
                        API
                    </Link>
                    <Link
                        href={route('contact')}
                        className={`ms-4 font-semibold text-gray-600 hover:text-gray-900  focus:outline focus:outline-2 focus:rounded-sm focus:outline-orange-500 ${route().current('contact') ? '!text-black' : ''}`}
                    >
                        Contact
                    </Link>
                </div>
                <div className="-me-2 flex items-center sm:hidden">
                    <button
                        onClick={() => setShowingNavigationDropdown((previousState) => !previousState)}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path
                                className={!showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                            <path
                                className={showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>

            </div>
            <div className={(showingNavigationDropdown ? 'block' : 'hidden') + ' sm:hidden'}>

                <div className="pt-1 pb-1 mt-5 border-t border-gray-200 ">
                    <div className="mt-3 space-y-1">
                    <ResponsiveNavLink href={route('login')}>Log in</ResponsiveNavLink>
                        <ResponsiveNavLink href={route('register')} as="button">Register</ResponsiveNavLink>
                        <ResponsiveNavLink href={route('contact')}>Contact</ResponsiveNavLink>
                    </div>
                </div>
            </div>
        </header>
    )
}
