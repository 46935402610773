import SrtParser, {Line} from "@qgustavor/srt-parser/src";
import {Subtitle} from "@/types";

export function timestampToSeconds(timestamp: string): number {
    const parts = timestamp.split(':');
    if (parts.length !== 3) {
        throw new Error('Invalid timestamp format');
    }

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const [seconds, milliseconds] = parts[2].split(',').map(val => parseInt(val, 10));

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
        throw new Error('Invalid timestamp format');
    }

    return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
}

export function parseSrt(content: string): Subtitle[] {
    const parser = new SrtParser();
    const parsed = parser.fromSrt(content);
    return parsed.map((item: Line, index: number) => {
        return {
            id: item.id,
            startTime: item.startTime as string,
            endTime: item.endTime as string,
            startTimeSeconds: timestampToSeconds(item.startTime as string),
            endTimeSeconds: parser.timestampToSeconds(item.endTime as string),
            text: item.text
        }
    })
}

export function subtitlesToSrt(subtitles: Subtitle[], withDeleted: boolean = false): string {
    return subtitles.filter(el => withDeleted || !el.deleted)
        .map((subtitle, idx) => {
            const id = subtitle.deleted ? `-${subtitle.id}` : idx + 1;
            const text = subtitle.text.split('\n')
                .map((line: string) => line.trim())
                .filter((line: string) => line.length > 0)
                .join('\n');
            return `${id}\n${subtitle.startTime} --> ${subtitle.endTime}\n${text}\n\n`
        }).join('').trim();
}

export function subtitlesToTxt(subtitles: Subtitle[]): string {
    return subtitles.filter(el => !el.deleted).map((subtitle, idx) => {
        return `${subtitle.text}\n\n`
    }).join('').trim();
}

export function subtitlesToVtt(subtitles: Subtitle[]): string {
    return `WEBVTT\n\n${subtitles.filter(el => !el.deleted).map((subtitle, idx) => {
        const startTime = subtitle.startTime.toString().replace(',', '.');
        const endTime = subtitle.endTime.toString().replace(',', '.');
        return `${idx + 1}\n${startTime} --> ${endTime}\n${subtitle.text}\n\n`
    }).join('')}.trim()`;
}
