import React, { ReactElement, useEffect } from 'react';
import Modal from "@/Components/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@/Components/Icons/CloseIcon';

export interface MultiViewModalProps {
    title?: string;
    show: boolean;
    showBackButton?: boolean;
    onClose: () => void;
    children: ReactElement[];
    extras?: ReactElement;
    maxWidth?: "sm" | "md" | "lg" | "xl" | "none" | "2xl" | undefined;
    currentIdx: number;
    onBack: () => void;
}

export function MultiViewModal({
   maxWidth,
   showBackButton,
   extras,
   title,
   show,
   onClose,
   children,
   currentIdx,
   onBack
}: MultiViewModalProps) {

    const renderNavBar = (): JSX.Element => (
        <div className="flex items-center p-4 md:p-7">
            <div className="w-8 h-8">
                {currentIdx > 0 && (showBackButton || showBackButton === undefined) && (
                    <button type="button"
                            title="Back"
                            onClick={onBack}
                            className="bg-transparent hover:bg-gray-200 text-gray-400 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            data-modal-hide="default-modal">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="w-4 h-4"
                            title="Back"
                        />
                    </button>
                )}
            </div>
            <div className="flex-grow flex justify-center">
                <h3 className="text-xl font-semibold text-gray-900">
                    {children[currentIdx].props.title || title || ''}
                </h3>
            </div>
            <button type="button"
                    onClick={onClose}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                    data-modal-hide="default-modal">
                <CloseIcon/>
                <span className="sr-only">Close modal</span>
            </button>
        </div>
    );

    const getViewStyle = (index: number): React.CSSProperties => {
        let offset = (index - (currentIdx + index)) * 100;
        return {
            transform: `translateX(${offset}%)`,
            width: '100%',
            flexShrink: 0
        };
    };

    return (
        <Modal maxWidth={maxWidth} show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                {extras && extras}
                {renderNavBar()}
                <div className="screen-wrapper flex">
                    {React.Children.map(children, (child, index) => (
                        <div className="transition-all" style={getViewStyle(index)}>
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
}
