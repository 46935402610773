import {ExtractionJobStatus} from "@/services/extractionJobManager";
import {ucFirst} from "@/utils/textFormatting";
import {PersistedJob} from "@/types";
import {Tooltip} from 'flowbite-react';
import {faCircleExclamation, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "@/Components/Spinner";
import {ExtractionMethod} from "@/types/enums";

export default function JobStatusIndicator({job}: { job: PersistedJob }) {
    const status = job.status || ExtractionJobStatus.Submitted;
    const progress = job.progress === undefined ? null : job.progress;
    let missingSubtitles = false;
    let tooltipContent = '';

    if (ExtractionJobStatus.isStarting(status) && progress === null) {
        tooltipContent = 'Starting';
    } else if (ExtractionJobStatus.isRunning(status)) {
        tooltipContent = "Processing";
    } else if (
        status === ExtractionJobStatus.Succeeded &&
        (!job.output_file_size || isSubtitlesLikelyEmpty(job.file_duration, job.output_file_size))
    ) {
        tooltipContent = emptySubtitlesErrorMessage(job.method, job.output_file_size || 0)
        missingSubtitles = true;
    } else {
        tooltipContent = ucFirst(status);
    }

    return (
        <Tooltip
            theme={{ target: 'flex items-center w-fit' }}
            content={<span className="max-w-60 inline-block">{tooltipContent}</span>}
            placement="auto"
        >
        <span className="inline-block">
            {
                ExtractionJobStatus.isRunning(status) ?
                    (
                        <span role="status">
                            {
                                progress === null ?
                                    (
                                        <span>
                                <Spinner />
                            <span className="sr-only">Loading...</span>
                            </span>
                                    ) :
                                    (<span
                                        className="text-blue-600 text-sm dark:text-blue-400 select-none">{Math.round(progress)}%</span>)
                            }

                        </span>
                    ) :


                    [ExtractionJobStatus.Failed, ExtractionJobStatus.Terminated, ExtractionJobStatus.Cancelled].includes(status) ?
                        (status === ExtractionJobStatus.Cancelled ?
                                <FontAwesomeIcon className="text-red-700" size="lg" icon={faCircleXmark}/> :
                                <FontAwesomeIcon className="text-red-700" size="lg" icon={faCircleExclamation}/>
                        )
                        :
                        (missingSubtitles ?
                                <FontAwesomeIcon className="text-yellow-500" size="lg" icon={faCircleExclamation}/>
                                :
                                <svg className="w-5 h-5 text-green-500 dark:text-green-400 flex-shrink-0"
                                     aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                        )
            }
        </span>
        </Tooltip>
    )
}

function emptySubtitlesErrorMessage(method: ExtractionMethod, outputSize: number) {
    if(outputSize === 0) {
        return method === ExtractionMethod.OCR ? "No subtitles found. Ensure the video has visible subtitles, or try using the audio method to the extract subtitles." :
            "No subtitles found. Ensure the audio contains speech.";
    } else {
        return method === ExtractionMethod.OCR ? "Subtitles may be incomplete. Ensure the video has visible subtitles, or try using the audio method to the extract subtitles." :
            "Subtitles may be incomplete. Ensure the audio contains speech."
    }
}

function isSubtitlesLikelyEmpty(fileDuration: number, subtitleBytes: number) {
    const durationMinutes = fileDuration / 60;
    const minBytesPerMinute = 20;
    return subtitleBytes / durationMinutes < minBytesPerMinute;
}
