import ProgressBar from "@/Components/ProgressBar";
import PurchaseModal from "@/Pages/Dashboard/Partials/PurchaseModal";
import {useState} from "react";
import axios from "axios";
import {formatMinutes} from "@/utils/textFormatting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWallet, faClock, faStopwatch, faFilm} from "@fortawesome/free-solid-svg-icons";
import {User} from "@/types";
import Popover from "@/Components/Popover";

export type CreditsProps = {
    credits: number,
    prices: Record<string, number>,
    inline?: boolean,
    user: User
}

export default function Credits({credits, prices, inline, user}: CreditsProps) {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);
    return (
        <div>
            <PurchaseModal
                show={showModal}
                onClose={closeModal}
                prices={prices}
            />
            <h5 className="mb-6 text-xl font-bold text-gray-900">
                <FontAwesomeIcon className="text-blue-600 hidden md:inline xl:hidden mr-1" icon={faFilm}/> Credits
            </h5>

            <div className={`${inline ? 'md:flex md:flex-row justify-between' : ''}`}>
                <div>
                    <p className="text-sm mb-0 text-gray-600">Extraction time remaining</p>
                    <div className="flex items-center mt-1">
                        <div className="mr-2 md:hidden xl:inline-block">
                            <FontAwesomeIcon className="text-blue-600" icon={faFilm}/>
                        </div>
                        <h4 className="text-sm xl:text-base font-bold">{formatMinutes(credits)}</h4>
                    </div>
                    {
                        user.is_trial && (
                            <Popover trigger="hover" content={<FreeTrialMessage/>}>
                                <span
                                    className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">Free Trial</span>
                            </Popover>

                        )
                    }
                </div>
                <button type="button"
                        onClick={() => setShowModal(true)}
                        className={` text-sm lg:text-base py-1.5 px-3 inline-block text-center rounded leading-normal text-gray-800 bg-gray-100 border border-gray-100 hover:text-gray-900 hover:bg-gray-200 hover:ring-0 hover:border-gray-200 focus:bg-gray-200 focus:border-gray-200 focus:outline-none focus:ring-0 mr-2 self-center ${!inline ? 'w-full mt-5' : 'mt-5 md:mt-0'}`}>
                    Purchase more
                </button>
            </div>
        </div>
    )
}

function FreeTrialMessage() {
    return (
        <div className="p-5 w-64">
            <h3 className="text-yellow-800 font-semibold mb-4">Free Trial</h3>
            <p className="text-sm">
                You can only extract one video at a time. Upgrade by purchasing credits, to extract multiple videos at once, and upload URLs.
            </p>
        </div>
    )
}
