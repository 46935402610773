import React, {
    createContext,
    Dispatch,
    FC,
    Fragment,
    PropsWithChildren,
    ReactNode,
    RefObject,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import ReactDOM from 'react-dom';
import { InertiaLinkProps, Link } from '@inertiajs/react';
import { Transition } from '@headlessui/react';

const DropDownContext = createContext<{
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    toggleOpen: () => void;
    triggerRef: RefObject<HTMLDivElement>;
}>({
    open: false,
    setOpen: () => {},
    toggleOpen: () => {},
    triggerRef: { current: null },
});

type DropdownProps = {
    onOpen?: () => void;
}

const Dropdown = ({ children, onOpen }: PropsWithChildren<DropdownProps>) => {
    const [open, setOpen] = useState(false);
    const triggerRef = useRef<HTMLDivElement>(null);

    const toggleOpen = () => {
        setOpen((previousState) => !previousState);
    };

    useEffect(() => {
        if (open && onOpen) {
            onOpen();
        }
    }, [open, onOpen]);

    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOpen(false);
                event.stopPropagation()
            }
        };

        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    return (
        <DropDownContext.Provider value={{ open, setOpen, toggleOpen, triggerRef }}>
            {children}
        </DropDownContext.Provider>
    );
};

type TriggerProps = {
    children: ((isOpen: boolean) => ReactNode) | ReactNode;
};

const Trigger: FC<TriggerProps> = ({ children }) => {
    const { open, setOpen, toggleOpen, triggerRef } = useContext(DropDownContext);
    return (
        <div>
            <div ref={triggerRef} onClick={toggleOpen}>
                {typeof children === 'function' ? children(open) : children}
            </div>
            {open && <div className="fixed inset-0 z-40" onClick={() => setOpen(false)}></div>}
        </div>
    );
}

const Content = ({
                     align = 'right',
                     width = '48',
                     contentClasses = 'py-1 bg-white',
                     children,
                     className
                 }: PropsWithChildren<{
    align?: 'left' | 'right',
    width?: '48',
    contentClasses?: string
    className?: string
}>) => {
    const { open, setOpen, triggerRef } = useContext(DropDownContext);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [opacity, setOpacity] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    const updatePosition = () => {
        if (triggerRef.current && contentRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const contentRect = contentRef.current.getBoundingClientRect();

            let top = triggerRect.bottom + window.scrollY;
            let left = align === 'left'
                ? triggerRect.left + window.scrollX
                : triggerRect.right - contentRect.width + window.scrollX;

            // Adjust position if dropdown goes off-screen
            if (left + contentRect.width > window.innerWidth) {
                left = window.innerWidth - contentRect.width - 10 + window.scrollX;
            }
            if (top + contentRect.height > window.innerHeight + window.scrollY) {
                top = triggerRect.top - contentRect.height - 8 + window.scrollY; // 8px padding
            }

            setPosition({ top, left });
            setOpacity(1);
        }
    };

    useEffect(() => {
        if (open) {
            setOpacity(0);
            const timeoutId = setTimeout(() => {
                updatePosition();
            }, 0);

            window.addEventListener('resize', updatePosition);
            window.addEventListener('scroll', updatePosition, true);

            return () => {
                clearTimeout(timeoutId);
                window.removeEventListener('resize', updatePosition);
                window.removeEventListener('scroll', updatePosition, true);
            };
        } else {
            setOpacity(0);
        }
    }, [open, align]);

    let alignmentClasses = 'origin-top';
    if (align === 'left') {
        alignmentClasses += ' ltr:origin-top-left rtl:origin-top-right left-0';
    } else if (align === 'right') {
        alignmentClasses += ' ltr:origin-top-right rtl:origin-top-left right-0';
    }

    let widthClasses = width === '48' ? 'w-48' : '';

    const content = (
        <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 transform"
            enterTo="opacity-100 transform"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 transform"
            leaveTo="opacity-0 transform"
        >
            <div
                ref={contentRef}
                style={{
                    position: 'absolute',
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                    zIndex: 9999,
                    opacity: opacity,
                    transition: 'opacity 150ms ease-out'
                }}
                className={`rounded-md shadow-lg ${alignmentClasses} ${widthClasses} ${className}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={`rounded-md ring-1 ring-black ring-opacity-5 ${contentClasses}`}>
                    {children}
                </div>
            </div>
        </Transition>
    );

    if (typeof window === 'undefined') {
        return null;
    }

    return ReactDOM.createPortal(
        <div>
            {open && <div className="fixed inset-0 z-40" onClick={() => setOpen(false)}></div>}
            {content}
        </div>,
        document.body
    );
};

const DropdownLink = ({ className = '', children, isExternal, ...props }: InertiaLinkProps & { isExternal?: boolean }) => {
    const { setOpen } = useContext(DropDownContext);
    const onFinished = () => setOpen(false);
    return (
        isExternal ? (
            <a
                href={props.href}
                onClick={(e) => {
                    setOpen(false);
                    return true;
                }}
                className={
                    'block cursor-pointer w-full px-4 py-2 text-start text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out ' +
                    className
                }
            >
                {children}
            </a>
        ) : (
            <Link
                {...props}
                onFinish={onFinished}
                className={
                    'block w-full px-4 py-2 text-start text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out ' +
                    className
                }
            >
                {children}
            </Link>
        )
    );
};

interface DropdownItemProps extends React.HTMLAttributes<HTMLElement> {
    className?: string;
    onClick?: (e: any) => any;
}

const DropdownItem = ({ className = '', children, ...props }: DropdownItemProps) => {
    const { setOpen } = useContext(DropDownContext);
    const handleClick = (e: any) => {
        if(props.onClick) {
            const result = props.onClick(e);
            if(result !== false) {
                setOpen(false);
            }
        }
    }
    return (
        <span
            {...props}
            onClick={handleClick}
            className={
                'cursor-pointer flex items-center px-4 py-2 text-start text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out ' +
                className
            }
        >
            {children}
        </span>
    );
}

Dropdown.Trigger = Trigger;
Dropdown.Content = Content;
Dropdown.Link = DropdownLink;
Dropdown.Item = DropdownItem;

export default Dropdown;
