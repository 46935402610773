import { useState, useEffect } from "react";

export type HistoryItem<T> = {
    changes: { index: number; oldItem: T }[];
};

const useHistory = <T>(initialItems: T[], localStorageKey: string = '', maxHistorySize: number = 200) => {
    const [items, setItems] = useState<T[]>(initialItems);
    const [history, setHistory] = useState<HistoryItem<T>[]>([]);
    const [redoHistory, setRedoHistory] = useState<HistoryItem<T>[]>([]);

    useEffect(() => {
        setItems(initialItems);
        setHistory(() => {
            const storedHistory = localStorageKey && localStorage.getItem(localStorageKey);
            return storedHistory ? JSON.parse(storedHistory) : [];
        });
        setRedoHistory([]);
    }, [initialItems]);

    useEffect(() => {
        if(localStorageKey) {
            localStorage.setItem(localStorageKey, JSON.stringify(history.slice(-maxHistorySize)));
        }
    }, [history, localStorageKey, maxHistorySize]);

    const updateItems = (changes: { index: number; item: T }[]) => {
        const newItems = [...items];
        const historyChanges: { index: number; oldItem: T }[] = [];

        changes.forEach(({ index, item }) => {
            const oldItem = { ...newItems[index] };
            newItems[index] = item;
            historyChanges.push({ index, oldItem });
        });

        setItems(newItems);
        setRedoHistory([]);

        setHistory((prevHistory) => {
            if (prevHistory.length === 0) {
                return [{ changes: historyChanges }];
            }

            const lastHistoryItem = prevHistory[prevHistory.length - 1];

            if (
                lastHistoryItem.changes.length === 1 &&
                historyChanges.length === 1 &&
                lastHistoryItem.changes[0].index === historyChanges[0].index
            ) {
                return [
                    ...prevHistory.slice(0, -1),
                    { changes: [{ ...historyChanges[0], oldItem: lastHistoryItem.changes[0].oldItem }] },
                ];
            }

            return [...prevHistory, { changes: historyChanges }];
        });
    };

    const undo = () => {
        if (history.length > 0) {
            const lastHistoryItem = history[history.length - 1];
            const newItems = [...items];
            const redoChanges: { index: number; oldItem: T }[] = [];

            lastHistoryItem.changes.forEach(({ index, oldItem }) => {
                redoChanges.push({ index, oldItem: newItems[index] });
                newItems[index] = oldItem;
            });

            setItems(newItems);
            setHistory((prevHistory) => prevHistory.slice(0, -1));
            setRedoHistory((prevRedoHistory) => [...prevRedoHistory, { changes: redoChanges }]);
        }
    };

    const redo = () => {
        if (redoHistory.length > 0) {
            const lastRedoItem = redoHistory[redoHistory.length - 1];
            const newItems = [...items];
            const historyChanges: { index: number; oldItem: T }[] = [];

            lastRedoItem.changes.forEach(({ index, oldItem }) => {
                historyChanges.push({ index, oldItem: newItems[index] });
                newItems[index] = oldItem;
            });

            setItems(newItems);
            setRedoHistory((prevRedoHistory) => prevRedoHistory.slice(0, -1));
            setHistory((prevHistory) => [...prevHistory, { changes: historyChanges }]);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === "z") {
                event.preventDefault();
                undo();
            } else if ((event.ctrlKey || event.metaKey) && event.key === "y") {
                event.preventDefault();
                redo();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [history, redoHistory]);

    return {
        items,
        updateItems,
        undo: history.length ? undo : null,
        redo: redoHistory.length ? redo : null
    };
};

export default useHistory;
