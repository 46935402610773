interface FAQItem {
    question: string;
    answer: string;
}

export const faqItems: FAQItem[] = [
    {
        question: 'Can I extract subtitles for free?',
        answer: `Yes, you get $\{free_hours} hours of free subtitle extraction when you sign up. This means you can extract subtitles from videos with a total duration of up to $\{free_hours} hours. If you need more, you can purchase additional processing time for as low as $0.25 per hour of video duration. There are no recurring expenses or subscriptions.`,
    },
    {
        question: 'What are hardcoded or burned-in subtitles?',
        answer: 'Hardcoded or burned-in subtitles are subtitle text permanently embedded into the video frames. They cannot be turned off or removed separately from the video.',
    },
    {
        question: 'Can I extract soft-coded subtitles?',
        answer: 'Yes, we can extract soft-coded subtitles that are part of the video file but not permanently embedded within the image frames. Unlike hardcoded subtitles, soft-coded subtitles can be turned on or off by the viewer, similar to using the option to toggle subtitles with your TV remote.',
    },
    {
        question: 'Can I create subtitles for videos that don\'t already have them?',
        answer: 'Yes, we offer two methods to generate subtitles for videos without existing subtitles. The vision method uses optical character recognition (OCR) to detect and extract any visible text within the video frames. The audio method employs speech recognition technology to transcribe the spoken dialogue from the video\'s audio track into subtitle text.',
    },
    {
        question: 'How long do you keep my uploaded videos?',
        answer: 'We delete all uploaded videos after 48 hours to protect your privacy.',
    },
    {
        question: 'How long does subtitle extraction take?',
        answer: 'On average, subtitle extraction takes about 5 minutes per video. However, this can vary depending on the video\'s duration and complexity.',
    },
    {
        question: 'Can I extract subtitles from YouTube or other online videos?',
        answer: 'Yes, there are two ways to extract subtitles from online videos. You can download the video and then upload it to our platform, which can be done using the free trial credits. Alternatively, you can import the video URL directly into our system, which is faster and easier but requires purchased processing credits.',
    },
    {
        question: 'Is there a size limit for video uploads?',
        answer: 'The maximum file size for video uploads is 5GB. If you have larger files, please contact us, and we\'ll do our best to assist you. While there is no limit on the video duration, there are minimum duration requirements for subtitle extraction. For vision-based extraction, the video must be at least 5 minutes long, and for audio-based extraction, the video must be at least 10 minutes long. For paid users, the (vision-based) minimum video duration is 1 minute.',
    },
];
