import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {PageProps} from '@/types';
import Form from "@/Pages/Contact/Form";
import GuestLayout from "@/Layouts/Guest/GuestLayout";
import PageLayout from "@/Layouts/PageLayout";
import {FaqsContent} from "@/Pages/Faq/Faqs";
import {Head} from "@inertiajs/react";
import React from "react";

export default function Contact({auth}: PageProps) {
    return (
        <PageLayout header={auth => auth ? <h1 className="font-semibold text-xl text-gray-800 leading-tight">Support</h1> : null}>
            <Head>
                <meta name="description" content="Contact us for support or feedback."/>
            </Head>
            {auth.user ?
                <div className="py-12">
                    <div className="container mx-auto sm:px-6 lg:px-8 space-y-6 lg:flex items-center justify-between">
                        <div className="p-4 lg:w-1/3 sm:p-8">
                            <header><h1 className="text-2xl lg:text-3xl  text-gray-900 mb-5">Contact Us</h1>
                                <p className="mt-1 text-lg text-gray-600">Got a technical issue? Want to send feedback
                                    about a feature? Let us know.</p>
                            </header>
                        </div>
                        <div className="p-4 lg:w-1/2 sm:p-8 mx-4 lg:mx-0 bg-white shadow rounded-lg">

                            <Form user={auth.user}/>
                        </div>
                    </div>
                </div>
                :
                <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex items-center justify-between">
                    <div className="p-4 lg:w-1/3 sm:p-8"><h2 className="text-3xl mb-4 mt-4">Contact Us</h2>
                        <p className="mt-1 text-base text-gray-600">Got a technical issue? Want to send feedback about a
                            feature? Let us know.</p></div>

                    <div className="p-4 lg:w-1/2 sm:p-8 mx-4 lg:mx-0 bg-white shadow-lg rounded-lg">
                        <Form/>
                    </div>

                </div>
            }
        </PageLayout>
    )
}
