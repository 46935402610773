import axios from "axios";
import {initProgressListener} from "@/utils/progressListener";
import ProgressSmoother, {ProgressSmootherConfig} from "@/utils/ProgressSmoother";
import {PersistedJob} from "@/types";
import InsufficientCreditsException from "@/exceptions/InsufficientCreditsException";

const progressConfig: ProgressSmootherConfig = {
    maxValue: 1,
    averageTimeBetweenValues: 1000 * 25,
    averageValueIncreaseDelta: 0.10,
    delayUntilFirstValue: 1000 * 25,
    firstValue: 0.35,
}

export class RefuseToTranslateError extends Error {
    constructor() {
        super("Translation declined. Content may be inappropriate.");
    }
}

export function translate(
    job: PersistedJob,
    targetLanguage: string,
    onProgress: (progress: number) => void,
): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
        const onComplete = () => resolve();
        const onError = (error: Error) => {
            if(error.message.includes("RefuseToTranslateError")) {
                reject(new RefuseToTranslateError());
            } else {
                reject(new Error("Failed to translate. Try again later."));
            }
        }
        const smoothingConfig = makeSmoothingConfig(job.file_duration);
        const apiRoute = route("subtitle.translate", {job: job.id})

        try {
            const res = await axios.post(apiRoute, { language: targetLanguage });
            const {id} = res.data;
            const progressSmoother = new ProgressSmoother(smoothingConfig);
            await initProgressListener(
                id,
                onProgress,
                onComplete,
                onError,
                progressSmoother,
                500
            );
        } catch (e: any) {
            if (e.response) {
                const data = e.response.data;
                const msg = data.message ? data.message : JSON.stringify(data);
                if(msg.includes("Insufficient credits")) {
                    reject(new InsufficientCreditsException(data.balance));
                }
                reject(new Error(msg));
            } else {
                onError(e);
            }
        }
    })
}

function makeSmoothingConfig(videoDuration: number): ProgressSmootherConfig {
    const hours = videoDuration / 3600;
    const config = {...progressConfig};
    const scaleFactor = Math.min(1 / hours, 1)
    config.firstValue *= scaleFactor;
    config.averageValueIncreaseDelta *= scaleFactor;
    return config;
}
