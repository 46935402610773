import {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import Dropdown from '@/Components/Dropdown';
import NavLink from '@/Components/NavLink';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink';
import {Link} from '@inertiajs/react';
import {PageProps, SessionFlash, User} from '@/types';
import {faUser, faFileLines} from "@fortawesome/free-regular-svg-icons";
import {faSliders, faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {Toaster} from "react-hot-toast";
import useSessionToast from "@/utils/useSessionToast";
import * as Sentry from "@sentry/react";
import { usePage } from '@inertiajs/react'
import {AnalyticsEvent, sendEvent, setUserData} from "@/services/analytics";
import Footer from "@/Layouts/Auth/Footer";
import {PaymentStatus} from "@/types/enums";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Authenticated({
    header = null,
    children,
    mainClassName,
    containerClassName = "min-h-screen flex flex-col bg-gray-100",
    showFooter = true
}: PropsWithChildren<{
    header?: ReactNode,
    mainClassName?: string
    showFooter?: boolean
    containerClassName?: string
}>) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);
    const { sessionFlash, auth } = usePage<PageProps>().props

    useSessionToast(sessionFlash);

    useEffect(() => {
        setUserData(auth.user);
        Sentry.setUser({id: auth.user.id})
    }, []);

    useEffect(() => {
        if (sessionFlash?.data?.payment) {
            const payment = sessionFlash.data.payment;
            const status = payment.status as PaymentStatus;
            if(status !== PaymentStatus.CANCELED) {
                sendEvent(AnalyticsEvent.PURCHASE, {
                    value: payment.amount / 100,
                    currency: 'USD',
                    transaction_id: payment.id,
                })
            }
            if(payment.async) {
                window.close();
            }
        }
    }, [sessionFlash]);

    return (
        <div className={containerClassName}>
            <nav className="bg-white border-b border-gray-100">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div className="shrink-0 flex items-center">
                                <Link href="/">
                                    <ApplicationLogo className="block h-9 w-auto fill-current text-gray-800"/>
                                </Link>
                            </div>

                            <div className="hidden space-x-8 sm:-my-px sm:ms-10 sm:flex">
                                <NavLink href={route('dashboard')} active={route().current('dashboard')}>
                                    Dashboard
                                </NavLink>
                                <NavLink href={route('api.info')} active={route().current('api*')}>
                                    API
                                </NavLink>
                                <NavLink href={route('contact')} active={route().current('contact')}>
                                    Support
                                </NavLink>
                            </div>
                        </div>

                        <div className="hidden sm:flex sm:items-center sm:ms-6">
                            <div className="ms-3">
                                <Dropdown>
                                    <Dropdown.Trigger>
                                        <span className="inline-flex rounded-md">
                                            <button
                                                type="button"
                                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                                            >
                                                {auth.user.name}

                                                <svg
                                                    className="ms-2 -me-0.5 h-4 w-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                        </span>
                                    </Dropdown.Trigger>

                                    <Dropdown.Content>
                                        <Dropdown.Link className="flex" href={route('profile.edit')}>
                                            <FontAwesomeIcon className="text-gray-500 w-4 h-4 pr-3 self-center" icon={faUser}/> Profile</Dropdown.Link>
                                        <Dropdown.Link className="flex" href={route('billing')}>
                                            <FontAwesomeIcon className="text-gray-500 w-4 h-4 pr-3 self-center" icon={faFileLines}/> Billing</Dropdown.Link>
                                        <Dropdown.Link className="flex" href={route('setting.edit')}>
                                            <FontAwesomeIcon className="text-gray-500 w-4 h-4 pr-3 self-center" icon={faSliders}/>  Settings</Dropdown.Link>
                                        <Dropdown.Link className="flex" href={route('logout')} method="post" as="button">
                                            <FontAwesomeIcon className="text-gray-500 w-4 h-4 pr-3 self-center" icon={faArrowRightFromBracket} />Log Out
                                        </Dropdown.Link>
                                    </Dropdown.Content>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="-me-2 flex items-center sm:hidden">
                            <button
                                onClick={() => setShowingNavigationDropdown((previousState) => !previousState)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                            >
                                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path
                                        className={!showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={(showingNavigationDropdown ? 'block' : 'hidden') + ' sm:hidden'}>

                    <div className="pt-4 pb-1 border-t border-gray-200">
                        <div className="px-4">
                            <div className="font-medium text-base text-gray-800">
                                {auth.user.name}
                            </div>
                            <div className="font-medium text-sm text-gray-500">{auth.user.email}</div>
                        </div>

                        <div className="mt-3 space-y-1">
                            <ResponsiveNavLink href={route('profile.edit')}>Profile</ResponsiveNavLink>
                            <ResponsiveNavLink href={route('billing')}>Billing</ResponsiveNavLink>
                            <ResponsiveNavLink method="post" href={route('logout')} as="button">
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </div>
            </nav>

            {header && (
                <header className="bg-white shadow">
                    <div className="container mx-auto py-5 md:py-6 px-4 sm:px-6 lg:px-8">{header}</div>
                </header>
            )}

            <main className={mainClassName}>
                {children}
                {showFooter && <Footer/>}
            </main>
            <Toaster/>
        </div>
    );
}
